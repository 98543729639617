<template>
    <div class="base-page">
        <div class="query-box">
            <el-form :inline="true" :model="query_form"  ref="query_form" class="demo-form-inline">
                <el-form-item label="选择月份：" prop="month">

                    <el-date-picker
                            size="mini"
                            v-model="query_form.month"
                            type="month"
                            unlink-panels

                            placeholder="月份"

                            @change="monthChang"
                    >
                    </el-date-picker>

                </el-form-item>


                <el-form-item label="查询对象：" prop="type" style="margin-left: 15px">
                    <el-select v-model="query_form.type" placeholder="请选择"  size="mini" style="width: 100px" >
                        <el-option
                                v-for="item in type_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="query_form.type==1">
                    <el-form-item  prop="order_channel" >
                        <el-select v-model="query_form.select_build_id" placeholder="请选择楼宇"  size="mini" style="width: 200px">
                            <el-option
                                    key="0"
                                    label="全部"
                                    value="0">
                            </el-option>
                            <el-option
                                    v-for="item in query_form.buildArr"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <template v-if="query_form.type==2">
                    <el-form-item  prop="order_channel">
                        <el-select v-model="query_form.select_unit_id" placeholder="请选择单元"  size="mini" style="width: 200px">
                            <el-option
                                    key="0"
                                    label="全部"
                                    value="0">
                            </el-option>
                            <el-option
                                    v-for="item in query_form.unitArr"
                                    :key="item.unit_id"
                                    :label="item.all_name"
                                    :value="item.unit_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <el-form-item>
                    <el-button size="mini" type="primary" @click="query">查询</el-button>
                </el-form-item>



            </el-form>


        </div >


        <div class="base-body" id="base-body">

            <div class="base-table" >

                <el-table
                        :data="dataList"
                        border
                        :height="tableHeight"
                        v-loading="loading"
                        :style="{width:tableWidth}"
                        ref="table"
                >
                    <template v-if="dataList.length>0">

                        <el-table-column label="楼宇/单元" prop="name" width="100" fixed="left">
                        </el-table-column>

                        <el-table-column label="上月底预收余额（收款周期：截止上月底，费用周期：当前月及以后）" >
                            <el-table-column
                                    v-for="item in columns1"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="本月分摊以往预收（收款周期：截止上月底，费用周期：当前月）" >
                            <el-table-column
                                    v-for="item in columns2"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="本月预收（收款周期：当前月，费用周期：下一月及以后）" >
                            <el-table-column
                                    v-for="item in columns3"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="本月底预收余额（收款周期：截止当前月底，费用周期：下一月及以后）" >
                            <el-table-column
                                    v-for="item in columns4"
                                    :key="item.prop"
                                    :prop="item.prop"
                                    :label="item.label"
                                    :width="item.width"
                                    :formatter="item.formatter"
                                    :fixed = "item.fixed"
                            >
                            </el-table-column>
                        </el-table-column>

                    </template>




                </el-table>
            </div>
        </div>


    </div>




</template>

<script>

    export default {
        name: "main_page",
        props:['upPageParams'],
        components:{

        },
        data() {
            return {

                loading:false,

                query_form:{

                    //查询对象
                    type:'1',//默认按楼宇
                    buildArr:[],//楼宇数据
                    unitArr:[],//单元数据
                    select_build_id:"0",//选择的楼宇，默认全部
                    select_unit_id:"0",//选择的单元，默认全部

                    month:'',
                },


                type_options: [ {
                    value: '1',
                    label: '按楼宇'
                }, {
                    value: '2',
                    label: '按单元'
                }],


                tableHeight: 0,
                tableWidth:0,
                columns1:[],
                columns2:[],
                columns3:[],
                columns4:[],


                dataList:[],


            }
        },
        created() {
            this.getBuildList()
            this.getUnitList()
        },
        mounted() {

            this.$nextTick(function() {
                this.tableHeight = document.getElementById("base-body").offsetHeight;
                this.tableWidth= document.getElementById("base-body").offsetWidth + 'px';
            })
        },
        methods:{

            //获取楼宇
            getBuildList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuild/getList',params).then((res)=>{
                    if (res.code ==1){

                        this.query_form.buildArr = res.data.list

                    }

                })
            },
            //获取单元列表
            getUnitList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuildUnit/getBuildUnitListByResidence',params).then((res)=>{
                    if (res.code ==1){
                        this.query_form.unitArr = res.data
                    }
                })
            },


            //费用期间变化
            monthChang(){

                if (this.query_form.month){
                    this.query_form.month = this.dayjs(this.query_form.month).format("YYYY-MM")

                }else {
                    this.query_form.month = ''
                }
            },


            //查询
            query(){
                if (this.query_form.month==''){
                    this.$alert('请选择月份', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    }).then(() => {

                    }).catch(() => {

                    });
                    return false
                }
                let params = {
                    residence_id:this.upPageParams.commiunity.id,
                    type:this.query_form.type,

                     month:this.query_form.month,
                }
                //楼栋或者单元id
                if (params.type==1){
                    params.id = this.query_form.select_build_id
                }else {
                    params.id = this.query_form.select_unit_id
                }


                this.loading = true
                this._postApi('/wy/data/chargeAdvanceShare/getList', params).then((res) => {
                    this.loading = false

                    if (res.code == 1) {

                        this.columns1 = [];
                        this.columns2 = [];
                        this.columns3 = [];
                        this.columns4 = [];
                        this.columns5 = [];

                        if (res.data.list.length > 0){
                            let head1 = res.data.sydys_head
                            let head2 = res.data.byftywys_head
                            let head3 = res.data.byys_head
                            let head4 = res.data.bydysye_head


                            for (let i=0;i<head1.length;i++){
                                this.columns1.push({
                                    label:head1[i]['title'],
                                    prop:head1[i]['field'],
                                    width:120
                                })
                            }


                            for (let i=0;i<head2.length;i++){
                                this.columns2.push({
                                    label:head2[i]['title'],
                                    prop:head2[i]['field'],
                                    width:120
                                })
                            }



                            for (let i=0;i<head3.length;i++){
                                this.columns3.push({
                                    label:head3[i]['title'],
                                    prop:head3[i]['field'],
                                    width:120
                                })
                            }


                            for (let i=0;i<head4.length;i++){
                                this.columns4.push({
                                    label:head4[i]['title'],
                                    prop:head4[i]['field'],
                                    width:120
                                })
                            }

                            this.dataList = res.data.list

                            //解决fixed 错位
                            this.$nextTick(function() {
                                this.$refs.table.doLayout()
                            })
                        }else {
                            this.dataList = res.data.list
                        }

                    } else {

                        this.$confirm(res.msg, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {

                        }).catch(() => {

                        });
                    }
                }).catch((err) => {
                    this.loading = false
                })


            },


            closeEvent(e) {
                if (e.type == 'close') {
                    this.dialog_order_info.open = false;
                }
            },

        }
    }
</script>

<style scoped>
    .query-box{
        padding: 15px;
        display: flex;flex-direction: row;
    }
    .base-page{
        width: 100%;height: 100%;
        display: flex;flex-direction: column;
    }

    .base-body{
        flex: 1;width: 100%;
    }

    .base-table{
        padding: 0 15px;
        border-radius: 5px;
        background: #ffffff;
        height: 100%;width: 100%;


    }
    .base-footer{
        text-align: right;
        padding: 5px;
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        /*color: #99a9bf;*/
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

</style>
