<template>
    <div class="con_page_container">
        <!-- 左边数据树 -->
        <div class="left_tree_box">
            <el-tree :props="props" node-key="id" ref="tree" :current-node-key="treeId" :load="loadNode" lazy
                     :expand-on-click-node="false" :highlight-current="true" @node-click="treeClick"></el-tree>
        </div>
        <!-- 内容 -->
        <div class="main_container">
            <div class="ch_temp_box">
                <main_page v-if="showView==1" :upPageParams="thisPageParams"></main_page>
            </div>
        </div>
    </div>
</template>

<script>
    import main_page from "./main_page.vue";
    var _this;
    export default {
        name: "index",
        components:{
            main_page
        },
        data() {
            return {
                communityList:[],//小区列表
                props: {
                    label: 'name',
                    children: 'zones',
                    isLeaf: 'leaf'
                },
                showView:0,//
                thisPageParams:{},
                treeId:0
            }
        },
        created: function() {
            _this = this;
        },
        mounted: function() {

        },
        methods: {
            /* * 获取小区列表 tree加载 */
            loadNode(node, resolve) {
                if (node.level === 0) {
                    //获取小区
                    _this._getApi('/wy/account/getResidenceListByAdmin', {}).then((res) => {
                        if (res.code == 1) {
                            var newData = res.data;
                            _this.thisPageParams.commiunity = newData[0];
                            _this.$nextTick(function(){
                                _this.$refs.tree.setCurrentKey(_this.thisPageParams.commiunity.id);//获取已经设置的资源后渲染
                            })
                            _this.showView = 1;
                            newData.forEach(function(item){
                                item.leaf = true;
                            })
                            return resolve(newData);
                        } else {

                        }
                    }).catch((err) => {})
                }
            },

            /* * tree 点击 */
            treeClick(data,node,_this_){
                // 小区被点击
                if(node.level == 1){
                    _this.thisPageParams.commiunity = data;
                    _this.showView = 0;
                    _this.$nextTick(function(){
                        _this.showView = 1;
                    })
                }
            },

            // /* * 楼宇 组件通信 */
            // buildEvent(e){
            //     switch(e.type){
            //         case 'add':
            //             var node = _this.$refs.tree.getNode(_this.selectCommunityId);   //要刷新的tree id
            //             node.loaded = false;
            //             node.expand();
            //             break;
            //     }
            // },
            // /* * 单元 组件通信 */
            // unitsEvent(e){
            //     switch(e.type){
            //         case 'add':
            //             var node = _this.$refs.tree.getNode(_this.unitsPageParams.build.id);   //要刷新的tree id
            //             node.loaded = false;
            //             node.expand();
            //             break;
            //     }
            // },

        }
    }
</script>

<style scoped>

</style>
